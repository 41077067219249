import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

import { Card } from '../layout/card'

import { DOUBLE_PADDING } from '../styles/theme'

/**
 * Props.
 */

interface Release {
  id: string
  artist: string
  title: string
  url: string
  img: {
    localFile: any // ImageDataLike
  }
}

interface Data {
  allStrapiRelease: {
    edges: ReadonlyArray<{ node: Release }>
  }
}

/**
 * Styles.
 */

const StyledReleasesWrapper = styled.div`
  display: flex;

  // @TODO: Fix me, this is a hack.
  max-width: 416px;
  overflow: hidden;

  padding: ${DOUBLE_PADDING};
`

const StyledLink = styled.a`
  border: ${(p) => p.theme.borders.default};

  &:not(:last-of-type) {
    margin-right: ${DOUBLE_PADDING};
  }
`

/**
 * Component.
 */

export const Releases = () => {
  return (
    <Card title="Record Label">
      <StyledReleasesWrapper>
        <StaticQuery
          query={releasesQuery}
          render={(data: Data) =>
            data.allStrapiRelease.edges.map(({ node: release }) => <Release key={release.id} {...release} />)
          }
        />
      </StyledReleasesWrapper>
    </Card>
  )
}

const Release = (release: Release) => {
  const image = getImage(release.img.localFile)

  return (
    <StyledLink href={release.url} target="_blank">
      <GatsbyImage image={image!} alt={`${release.artist} - ${release.title}`} />
    </StyledLink>
  )
}

/**
 * Query.
 */

const releasesQuery = graphql`
  query ReleasesQuery {
    allStrapiRelease {
      edges {
        node {
          artist
          id
          title
          url
          img {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 100, placeholder: BLURRED, aspectRatio: 1, layout: FIXED)
              }
            }
          }
        }
      }
    }
  }
`
