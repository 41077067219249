import React from 'react'
import styled from 'styled-components'

import { Shadow } from '../layout/shadow'
import { DOUBLE_PADDING, PADDING } from '../styles/theme'

/**
 * Styles.
 */

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: ${DOUBLE_PADDING} 0;

  background-color: ${(p) => p.theme.colors.secondary};
  border: ${(p) => p.theme.borders.default};
`

const StyledExplanationWrapper = styled.div`
  display: flex;

  padding: 0 ${DOUBLE_PADDING} ${DOUBLE_PADDING};

  > * {
    &:not(:last-child) {
      margin-right: ${DOUBLE_PADDING};
    }
  }
`

const StyledExplanation = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  > * {
    &:not(:last-child) {
      margin-bottom: ${DOUBLE_PADDING};
    }
  }
`

const StyledHeader = styled.h1`
  margin: 0;

  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
`

const StyledPriceWrapper = styled.div`
  padding: ${DOUBLE_PADDING};

  background-color: pink;
  border: ${(p) => p.theme.borders.default};

  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
`

const StyledTextWrapper = styled.div`
  flex: 1;
  padding: ${DOUBLE_PADDING};

  background-color: ${(p) => p.theme.colors.white};
  border: ${(p) => p.theme.borders.default};

  text-align: center;
  font-size: 12px;
`

const StyledCtaWrapper = styled.div`
  display: flex;

  background-color: ${(p) => p.theme.colors.white};
  border-top: ${(p) => p.theme.borders.default};
  border-bottom: ${(p) => p.theme.borders.default};
`

const StyledCta = styled.span`
  flex: 1;

  padding: ${PADDING} 0;

  text-align: center;
  text-transform: uppercase;
  font-weight: bold;

  color: red;
`

const StyledPhrase = styled.p`
  margin: 0;
`

/**
 * Component.
 */

export const Support = () => (
  <Shadow>
    <StyledWrapper>
      <StyledExplanationWrapper>
        <StyledExplanation>
          <StyledHeader>Support</StyledHeader>
          <StyledPriceWrapper>?€ month subscription</StyledPriceWrapper>
          <StyledTextWrapper>
            HOW MUCH?
            <StyledPhrase>You pay what you want, what you can.</StyledPhrase>
            <StyledPhrase>1 or 2 euros every month is great.</StyledPhrase>
            <StyledPhrase>You can unsubscribe anytime you want.</StyledPhrase>
          </StyledTextWrapper>
        </StyledExplanation>

        <StyledTextWrapper>
          WHAT YOU GET
          <ul>
            <li>• All 6 radio streams unlocked : RADIO, CHILL, PARTY, STREET, TRIPPY, MIXTAPE</li>
            <li>• Exclusive Spotify playlists</li>
            <li>• 10% reduction price on the record label catalog, merch and vinyls</li>
            <li>• Exclusive newsletter with advanced updates on all projects and behind the scene goodies</li>
            <li>• Download links for mixtapes and DJ edits</li>
            <li>++ A surprise 🙈</li>
          </ul>
        </StyledTextWrapper>
      </StyledExplanationWrapper>
      <StyledCtaWrapper>
        <StyledCta>Subscribe</StyledCta>
        <StyledCta>One-shot</StyledCta>
      </StyledCtaWrapper>
    </StyledWrapper>
  </Shadow>
)
