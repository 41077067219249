import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

import { Card } from '../layout/card'

import { DOUBLE_PADDING, PADDING } from '../styles/theme'

/**
 * Props.
 */

interface Article {
  id: string
  title: string
  body: string
  img: {
    localFile: any // ImageDataLike
  }
}

interface Data {
  allStrapiArticle: {
    edges: ReadonlyArray<{ node: Article }>
  }
}

/**
 * Styles.
 */

const StyledArticlesWrapper = styled.div`
  display: flex;

  // @TODO: Fix me, this is a hack.
  max-width: 416px;
  overflow: hidden;
`

const StyledArticle = styled.div`
  min-width: 75%;

  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'image title'
    'image body';

  &:not(:last-of-type) {
    border-right: ${(p) => p.theme.borders.default};
  }
`

const StyledImage = styled(GatsbyImage)`
  grid-area: image;
`

const StyledTitle = styled.h1`
  grid-area: title;

  padding: ${PADDING} ${DOUBLE_PADDING};
  margin: 0;

  font-size: 14px;
`

const StyledBody = styled(ReactMarkdown)`
  grid-area: body;

  padding: 0 ${DOUBLE_PADDING} ${PADDING};

  font-size: 12px;
`

/**
 * Component.
 */

export const Articles = () => {
  return (
    <Card title="News">
      <StyledArticlesWrapper>
        <StaticQuery
          query={articlesQuery}
          render={(data: Data) =>
            data.allStrapiArticle.edges.map(({ node: article }) => <ArticleComponent key={article.id} {...article} />)
          }
        />
      </StyledArticlesWrapper>
    </Card>
  )
}

const ArticleComponent = (article: Article) => {
  const image = getImage(article.img.localFile)

  return (
    <StyledArticle>
      <StyledImage image={image!} alt={article.title} />
      <StyledTitle>{article.title}</StyledTitle>
      <StyledBody source={article.body} disallowedTypes={['paragraph']} unwrapDisallowed />
    </StyledArticle>
  )
}

/**
 * Query.
 */

const articlesQuery = graphql`
  query ArticlesQuery {
    allStrapiArticle {
      edges {
        node {
          id
          title
          body
          img {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 150, placeholder: BLURRED, aspectRatio: 1, layout: FIXED)
              }
            }
          }
        }
      }
    }
  }
`
