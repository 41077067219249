import { createGlobalStyle } from 'styled-components'

import { reset } from './reset'
import { theme } from './theme'

export const GlobalStyles = createGlobalStyle`
  ${reset}
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    height: 100%;
    margin: 0;
    padding: 0;

    background-color: ${theme.colors.background};
  }
`
