import { createRef } from 'react'

import { AudioActions, AudioActionsEnum } from './audioActions'

import { RadioStreamsEnum } from '../../../../commons/streams'

/**
 * Constants.
 */

const COVER_PLACEHOLDER_URL = 'https://via.placeholder.com/300'

/**
 * Refs.
 */

export const audioRef = createRef<HTMLAudioElement>()

/**
 * Types.
 */

export interface AudioState {
  selectedStream: RadioStreamsEnum
  isPlaying: boolean
  artist?: string
  track?: string
  coverUrl: string
}

/**
 * Reducer.
 */

export const audioReducer = (state: AudioState, action: AudioActions): AudioState => {
  switch (action.type) {
    case AudioActionsEnum.SELECT_STREAM:
      return {
        ...state,
        selectedStream: action.payload.selectedStream,
      }

    case AudioActionsEnum.PLAY:
      return {
        ...state,
        isPlaying: true,
      }

    case AudioActionsEnum.PAUSE:
      return {
        ...state,
        isPlaying: false,
      }

    case AudioActionsEnum.SET_INFO:
      return {
        ...state,
        ...action.payload,
      }

    case AudioActionsEnum.SET_COVER: {
      // Discogs may return a `spacer.gif` URL, let's remove it.
      const coverUrl = action.payload?.includes('spacer.gif') ? COVER_PLACEHOLDER_URL : action.payload

      return {
        ...state,
        coverUrl: coverUrl || COVER_PLACEHOLDER_URL,
      }
    }

    default:
      throw new Error('Unhandled action type!')
  }
}

/**
 * Default state.
 */

export const AUDIO_DEFAULT_STATE: AudioState = {
  selectedStream: RadioStreamsEnum.RADIO,
  isPlaying: true,
  coverUrl: COVER_PLACEHOLDER_URL,
}
