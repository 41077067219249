import React from 'react'
import styled from 'styled-components'

import { Block } from '../layout/block'
import { DOUBLE_PADDING, PADDING } from '../styles/theme'

/**
 * Styles.
 */

const StyledBlock = styled(Block)`
  display: flex;
  align-items: center;

  padding: ${PADDING};
`

const StyledInput = styled.input`
  margin: 0 ${DOUBLE_PADDING};
  flex: 1;

  border: 0;
  border-bottom: ${(p) => p.theme.borders.light};
`

const StyledText = styled.span`
  text-transform: uppercase;
  font-style: italic;

  color: ${(p) => p.theme.colors.black};
`

const StyledButton = styled.button`
  text-transform: uppercase;
  font-style: italic;

  color: ${(p) => p.theme.colors.secondary};

  background-color: transparent;
  border: 0;
`

/**
 * Component.
 */

export const Newsletter = () => (
  <StyledBlock fill>
    <StyledText>Newsletter</StyledText>
    <StyledInput type="email" />
    <StyledButton>OK</StyledButton>
  </StyledBlock>
)
