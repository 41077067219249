import React from 'react'
import styled from 'styled-components'

import { Block } from '../layout/block'
import { Shadow } from '../layout/shadow'
import { DOUBLE_PADDING } from '../styles/theme'

/**
 * Styles.
 */

const StyledBlock = styled(Block)`
  display: flex;
  align-items: center;
`

const StyledButton = styled.button`
  padding: ${DOUBLE_PADDING};

  flex: 1;

  text-transform: uppercase;
  font-weight: bold;

  background-color: transparent;
  border: 0;

  &:not(:last-of-type) {
    border-right: ${(p) => p.theme.borders.default};
  }

  &:hover {
    background-color: pink;
  }
`

/**
 * Component.
 */

export const LoginButtons = () => (
  <Shadow>
    <StyledBlock fill>
      <StyledButton>Login</StyledButton>
      <StyledButton>Sign Up</StyledButton>
    </StyledBlock>
  </Shadow>
)
