import React, { FC } from 'react'
import styled from 'styled-components'

import { DOUBLE_PADDING, SHADOW_SIZE } from '../styles/theme'

/**
 * Styles.
 */

const StyledColumn = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin-right: ${SHADOW_SIZE};

  > * {
    &:not(:last-child) {
      margin-bottom: ${DOUBLE_PADDING};
    }
  }
`

/**
 * Component.
 */

export const Column: FC = ({ children }) => <StyledColumn>{children}</StyledColumn>
