import React from 'react'
import styled from 'styled-components'

import { Block } from '../layout/block'
import { DOUBLE_PADDING } from '../styles/theme'

/**
 * Styles.
 */

const StyledBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${DOUBLE_PADDING};
`

const StyledHeader = styled.h1`
  margin: 0;
  margin-bottom: ${DOUBLE_PADDING};

  text-transform: uppercase;
  font-style: italic;

  color: red;
`

const StyledCtaWrapper = styled.div`
  flex: 1;
  display: flex;
`

const StyledCta = styled.span`
  flex: 1;

  padding: 0 ${DOUBLE_PADDING};

  text-transform: uppercase;
  font-weight: bold;
`

/**
 * Component.
 */

export const DownloadCta = () => (
  <StyledBlock fill>
    <StyledHeader>Download the app!</StyledHeader>
    <StyledCtaWrapper>
      <StyledCta>iPhone</StyledCta>
      <StyledCta>Android</StyledCta>
    </StyledCtaWrapper>
  </StyledBlock>
)
