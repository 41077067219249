import React from 'react'
import styled from 'styled-components'

import { Block } from '../layout/block'
import { Card } from '../layout/card'
import { Shadow } from '../layout/shadow'

import { DOUBLE_PADDING } from '../styles/theme'

/**
 * Styles.
 */

const StyledWrapper = styled(Shadow)`
  margin: 0 10%;
`

const StyledLinksWrapper = styled(Block)`
  flex: 1;
  min-width: 0;

  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
`

const StyledLink = styled.div`
  padding: ${DOUBLE_PADDING} 0;

  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledTextWrapper = styled.div`
  min-width: 0;

  margin: ${DOUBLE_PADDING};

  display: inline-flex;
  flex-direction: column;
`

const StyledSpan = styled.span`
  text-align: center;

  text-transform: none;
  font-style: normal;

  &:not(:last-of-type) {
    margin-bottom: ${DOUBLE_PADDING};
  }
`

/**
 * Component.
 */

export const Social = () => (
  <StyledWrapper>
    <StyledLinksWrapper fill>
      <StyledLink>LOGO</StyledLink>
      <StyledLink>LOGO</StyledLink>
      <StyledLink>LOGO</StyledLink>
      <StyledLink>LOGO</StyledLink>
    </StyledLinksWrapper>
    <Card title={SocialTitle}>
      <StyledTextWrapper>
        <StyledSpan>CONTACT / DJ BOOKINGS:</StyledSpan>
        <StyledSpan>contact@secousse.tv</StyledSpan>
      </StyledTextWrapper>
    </Card>
  </StyledWrapper>
)

const SocialTitle = (
  <StyledTextWrapper>
    <StyledSpan>From the hood to the moon, since 2006.</StyledSpan>
    <StyledSpan>Founded by Etienne ‘DJ’ Tron.</StyledSpan>
  </StyledTextWrapper>
)
