import { Dispatch } from 'react'

import { audioRef } from './audioReducer'

import { radioStreams, RadioStreamsEnum, streamBaseUrl } from '../../../../commons/streams'

/**
 * Action enums
 */

export enum AudioActionsEnum {
  SELECT_STREAM = '@@audio/SELECT_STREAM',
  PLAY = '@@audio/PLAY',
  PAUSE = '@@audio/PAUSE',
  SET_INFO = '@@audio/SET_INFO',
  SET_COVER = '@@audio/SET_COVER',
}

/**
 * Action types.
 */

interface SelectStreamActionPayload {
  type: AudioActionsEnum.SELECT_STREAM
  payload: {
    selectedStream: RadioStreamsEnum
  }
}

interface PlayActionPayload {
  type: AudioActionsEnum.PLAY
}

interface PauseActionPayload {
  type: AudioActionsEnum.PAUSE
}

interface SetInfoActionPayload {
  type: AudioActionsEnum.SET_INFO
  payload: {
    artist?: string
    track?: string
  }
}

interface SetCoverActionPayload {
  type: AudioActionsEnum.SET_COVER
  payload?: string
}

/**
 * Actions.
 */

export const selectStream = (dispatch: Dispatch<AudioActions>, selectedStream: RadioStreamsEnum) => {
  dispatch({
    type: AudioActionsEnum.SELECT_STREAM,
    payload: {
      selectedStream,
    },
  })
}

// @TODO: Fix play and pause, because methods are asynchronous.

export const play = (dispatch: Dispatch<AudioActions>) => {
  if (audioRef.current?.paused) {
    audioRef.current.play()
    dispatch({ type: AudioActionsEnum.PLAY })
  }
}

export const pause = (dispatch: Dispatch<AudioActions>) => {
  if (audioRef.current && !audioRef.current.paused) {
    audioRef.current.pause()
    dispatch({ type: AudioActionsEnum.PAUSE })
  }
}

export const setInfo = (dispatch: Dispatch<AudioActions>, info: { artist?: string; track?: string }) => {
  dispatch({ type: AudioActionsEnum.SET_INFO, payload: info })
}

export const setCover = (dispatch: Dispatch<AudioActions>, coverUrl?: string) => {
  dispatch({ type: AudioActionsEnum.SET_COVER, payload: coverUrl })
}

/**
 * Union types.
 */

export type AudioActions =
  | SelectStreamActionPayload
  | PlayActionPayload
  | PauseActionPayload
  | SetInfoActionPayload
  | SetCoverActionPayload

/**
 * Helpers.
 */

export const getStreamUrl = (selectedStream: RadioStreamsEnum) => {
  const { streamEndpoint } = radioStreams.find((stream) => stream.id === selectedStream)!

  return `${streamBaseUrl}/${streamEndpoint}`
}
