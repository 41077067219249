const palettes = [
  {
    primary: '#2DBBDE',
    secondary: '#FFE71A',
    tertiary: '#FFCEBD',
    quaternary: '#FF001E',
    quinary: '#FFCEBD',
  },
  {
    // logo should be white!
    primary: '#A6E4DC',
    secondary: '#FD6C21',
    tertiary: '#E11022',
    quaternary: '#2396FC',
    quinary: '#2396FC',
  },
  {
    primary: '#947863',
    secondary: '#f42d55',
    tertiary: '#afeeff',
    quaternary: '#00be56',
    quinary: '#afeeff',
  },
  {
    primary: '#ea282e',
    secondary: '#25c58c',
    tertiary: '#fedc5c',
    quaternary: '#1a99fd',
    quinary: '#fedc5c',
  },
  {
    primary: '#16a048',
    secondary: '#1a9afc',
    tertiary: '#ffec7e',
    quaternary: '#ff3642',
    quinary: '#ffec7e',
  },
]

export const chooseRandomPalette = () => palettes[Math.floor(Math.random() * palettes.length)]
