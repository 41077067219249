export enum RadioStreamsEnum {
  RADIO = 'radio',
  PARTY = 'party',
  CHILL = 'chill',
  STREET = 'street',
  MIX = 'mix',
  CHARTS = 'charts',
}

export const streamBaseUrl = 'http://stream.secousse.org:8000'

export const radioStreams = [
  {
    id: RadioStreamsEnum.RADIO,
    title: 'Radio',
    description: 'Non stop local music, old and new, all day everyday',
    streamEndpoint: 'secousse',
  },
  {
    id: RadioStreamsEnum.PARTY,
    title: 'Party',
    description: 'Need we say more? Now get on the floor',
    streamEndpoint: 'secousse-party',
  },
  {
    id: RadioStreamsEnum.CHILL,
    title: 'Chill',
    description: 'Sit back and relax',
    streamEndpoint: 'secousse-chill',
  },
  {
    id: RadioStreamsEnum.STREET,
    title: 'Street',
    description: 'Hardcore gangsta music, straight from the gutter',
    streamEndpoint: 'secousse-street',
  },
  {
    id: RadioStreamsEnum.MIX,
    title: 'Mix',
    description: 'Strictly DJ mixed music',
    streamEndpoint: 'secousse-mix',
  },
  {
    id: RadioStreamsEnum.CHARTS,
    title: 'Charts',
    description: 'The best new shit poppin in the music game',
    streamEndpoint: 'secousse-charts',
  },
]
