import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { Card } from '../layout/card'

/**
 * Constants.
 */

const chatboxOptions = {
  handle: 'pocdidier2',
  arch: 'js',
  styles: {
    a: 'CC0000',
    c: '000000',
    d: 'FFFFFF',
    k: '000000',
    l: '000000',
    m: 'CC0000',
    n: 'FFFFFF',
    p: '10',
    q: '000000',
    t: 0,
    ab: false,
    usricon: 0,
    surl: 0,
    allowpm: 0,
    showx: 0,
  },
}

/**
 * Styles.
 */

const StyledChatboxWrapper = styled.div`
  height: 200px;
`

/**
 * Component.
 */

// @TODO: Check if the element is displayed, and display something if it's not.
// This might be because of ad blocker!

export const Chatbox = () => {
  const chatRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//st.chatango.com/js/gz/emb.js'
    script.id = 'cid0020000277585129302'
    // @ts-ignore
    script['data-cfasync'] = 'false'
    script.async = true
    // @ts-ignore
    script.style = 'width: 100%;height: 100%;'
    script.innerText = JSON.stringify(chatboxOptions)
    chatRef.current?.appendChild(script)

    return () => {
      chatRef.current?.removeChild(script)
    }
  }, [])

  return (
    <Card title="Chatbox">
      <StyledChatboxWrapper ref={chatRef} />
    </Card>
  )
}
