import React, { FC } from 'react'
import styled from 'styled-components'

import { DOUBLE_PADDING, SHADOW_SIZE } from '../styles/theme'

/**
 * Props.
 */

interface ShadowProps {
  // We need to use className to be able to extend the styled component.
  className?: string
}

/**
 * Styles.
 */

const StyledWrapper = styled.div`
  position: relative;
`

const StyledShadow = styled.div`
  z-index: 0;

  position: absolute;
  top: ${SHADOW_SIZE};
  bottom: -${SHADOW_SIZE};
  left: ${SHADOW_SIZE};
  right: -${SHADOW_SIZE};

  background-color: ${(p) => p.theme.colors.black};
`

const StyledChildrenWrapper = styled.div`
  z-index: 1;

  position: relative;

  > * {
    &:not(:last-child) {
      margin-bottom: ${DOUBLE_PADDING};
    }
  }
`

/**
 * Component.
 */

export const Shadow: FC<ShadowProps> = ({ className, children }) => (
  <StyledWrapper className={className}>
    <StyledShadow />
    <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
  </StyledWrapper>
)
