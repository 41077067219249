import React, { FC } from 'react'
import styled from 'styled-components'

import { radioStreams } from '../../../commons/streams'

import { selectStream } from '../contexts/audio/audioActions'
import { useAudioDispatch, useAudioState } from '../contexts/audio/audioContext'

import { DOUBLE_PADDING, PADDING } from '../styles/theme'

/**
 * Styles.
 */

const StyledStreamsWrapper = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border: ${(p) => p.theme.borders.default};
`

const StyledStreamsTitle = styled.div`
  display: flex;
  justify-content: center;

  padding: ${PADDING} 0;
  margin-bottom: ${PADDING};

  background-color: ${(p) => p.theme.colors.secondary};
  border-bottom: ${(p) => p.theme.borders.default};

  text-transform: uppercase;
`

const StyledStreams = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: ${PADDING};

  padding: ${PADDING};
  margin-bottom: ${PADDING};

  background-color: ${(p) => p.theme.colors.secondary};
  border-top: ${(p) => p.theme.borders.default};
  border-bottom: ${(p) => p.theme.borders.default};
`

const StyledStreamButton = styled.button<{ $isSelected?: boolean }>`
  padding: ${DOUBLE_PADDING};
  background-color: ${(p) => (p.$isSelected ? 'red' : 'pink')};
  border: ${(p) => p.theme.borders.default};
`

/**
 * Component.
 */

export const Streams: FC = () => {
  const dispatch = useAudioDispatch()
  const { selectedStream } = useAudioState()

  return (
    <StyledStreamsWrapper>
      <StyledStreamsTitle>The Radios</StyledStreamsTitle>
      <StyledStreams>
        {radioStreams.map((stream) => (
          <StyledStreamButton
            key={stream.id}
            $isSelected={stream.id === selectedStream}
            onClick={() => selectStream(dispatch, stream.id)}
          >
            {stream.title}
          </StyledStreamButton>
        ))}
      </StyledStreams>
    </StyledStreamsWrapper>
  )
}
