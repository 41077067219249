import { chooseRandomPalette } from '../../../commons/palettes'

const BORDER_WIDTH = '2px'

export const PADDING = '5px'
export const DOUBLE_PADDING = '10px'

export const SHADOW_SIZE = '5px'

const palette = chooseRandomPalette();

export const theme = {
  colors: {
    background: '#E5E5E6',
    ...palette,
    white: '#fff',
    black: '#000',
  },
  borders: {
    light: '1px solid #000',
    default: `${BORDER_WIDTH} solid #000`,
  },
  shadows: {
    default: `${SHADOW_SIZE} ${SHADOW_SIZE} 0 0 #000`,
  },
  maxWidth: '1200px',
  maxWidthText: '720px',
  breakpoints: {
    xs: '320px',
    s: '600px',
    m: '900px',
    l: '1200px',
  },
}
