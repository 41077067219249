import React, { FC } from 'react'
import styled, { ThemeProvider } from 'styled-components'

import { GlobalStyles } from '../styles/globalStyles'
import { DOUBLE_PADDING, PADDING, theme } from '../styles/theme'

/**
 * Styles.
 */

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  @media (min-width: ${p => p.theme.breakpoints.s}) {
    padding: 1rem 1rem 3rem 1rem;
  }
`

const StyledMain = styled.main`
  flex: 1;

  padding: 1rem;

  background-color: ${(p) => p.theme.colors.primary};
`

const StyledInner = styled.div`
  max-width: ${(p) => p.theme.maxWidth};
  margin: auto;

  display: grid;
  column-gap: 1rem;

  @media (min-width: ${p => p.theme.breakpoints.l}) {
    grid-template-columns: 2fr 3fr 3fr;
  }
`

/**
 * Component.
 */

export const Layout: FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <StyledWrapper>
      <StyledMain>
        <StyledInner>{children}</StyledInner>
      </StyledMain>
    </StyledWrapper>
  </ThemeProvider>
)
