import React from 'react'
import { Helmet } from 'react-helmet'

import { Block } from '../layout/block'
import { Column } from '../layout/column'
import { Layout } from '../layout/layout'
import { Shadow } from '../layout/shadow'

import { Articles } from '../components/articles'
import { AudioPlayer } from '../components/audioPlayer'
import { Chatbox } from '../components/chatbox'
import { DownloadCta } from '../components/downloadCta'
import { LoginButtons } from '../components/loginButtons'
import { Logo } from '../components/logo'
import { Newsletter } from '../components/newsletter'
import { Releases } from '../components/releases'
import { Social } from '../components/social'
import { Streams } from '../components/streams'
import { Support } from '../components/support'

import { AudioProvider } from '../contexts/audio/audioContext'

const Home = () => {
  return (
    <Layout>
      <Helmet title="Secousse Radio">
        <meta
          name="description"
          content="Tune in to the rugged world of Secousse: a web-radio dedicated to the most forgotten, exotic, misunderstood, rebellious, underestimated and controversial musical treasures from all eras and continents."
        />
        <meta
          name="keywords"
          content="radio, dj, music, pop, rap, reggae, afro, disco, club, records, vinyl, black, urban, folk, sound, exotic, rare groove, funk, africa, hip hop, afrique, zouk, fm"
        />
      </Helmet>
      <Column>
        <Logo />
        <Social />
        <Newsletter />
        <Chatbox />
      </Column>
      <Column>
        <AudioProvider>
          <Shadow>
            <AudioPlayer />
            <Streams />
            <Block fill>Listen in iTunes</Block>
          </Shadow>
        </AudioProvider>
      </Column>
      <Column>
        <LoginButtons />
        <DownloadCta />
        <Support />
        <Articles />
        <Releases />
      </Column>
    </Layout>
  )
}

export default Home
