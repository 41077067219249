import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

import { Block, BlockProps } from './block'
import { Shadow } from './shadow'

import { PADDING } from '../styles/theme'

/**
 * Props.
 */

interface CardProps extends BlockProps {
  title: ReactNode
}

/**
 * Styles.
 */

const StyledBlock = styled(Block)`
  flex-direction: column;
`

const StyledCardTitle = styled.div`
  display: flex;
  justify-content: center;

  padding: ${PADDING} 0;

  background-color: ${(p) => p.theme.colors.secondary};
  border-bottom: ${(p) => p.theme.borders.default};

  text-transform: uppercase;
  font-style: italic;
`

/**
 * Component.
 */

export const Card: FC<CardProps> = ({ title, children, ...rest }) => (
  <Shadow>
    <StyledBlock fill {...rest}>
      <StyledCardTitle>{title}</StyledCardTitle>
      {children}
    </StyledBlock>
  </Shadow>
)
