import React, { FC } from 'react'
import styled from 'styled-components'

import { play, pause } from '../contexts/audio/audioActions'
import { useAudioDispatch, useAudioState } from '../contexts/audio/audioContext'

import { Block } from '../layout/block'

import { DOUBLE_PADDING, PADDING } from '../styles/theme'

/**
 * Styles.
 */

const StyledPlayerWrapper = styled(Block)`
  display: grid !important;
  grid-template-areas:
    'cover'
    'player';
`

const StyledCoverWrapper = styled(Block)`
  grid-area: cover;
`

// @TODO: Fix styles issues.
const StyledCover = styled.img`
  width: 100%;
`

const StyledAudioPlayerWrapper = styled(Block)`
  grid-area: player;

  display: grid !important;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'controls info';

  align-items: center;

  margin: -${DOUBLE_PADDING} ${DOUBLE_PADDING} 0 ${DOUBLE_PADDING};
  padding: ${PADDING};
`

const StyledControlsWrapper = styled.div`
  grid-area: controls;

  margin-right: ${PADDING};
`

const StyledInfoWrapper = styled.div`
  grid-area: info;

  display: flex;
  flex-direction: column;

  padding: ${DOUBLE_PADDING};

  background-color: ${(p) => p.theme.colors.primary};
  border: ${(p) => p.theme.borders.default};

  overflow: hidden;
`

const StyledInfoText = styled.span`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledArtist = styled(StyledInfoText)`
  font-weight: bold;
`

const StyledTrack = styled(StyledInfoText)``

/**
 * Component.
 */

export const AudioPlayer: FC = () => {
  const dispatch = useAudioDispatch()
  const { artist, track, coverUrl } = useAudioState()

  return (
    <StyledPlayerWrapper disableBorder>
      <StyledCoverWrapper>
        <StyledCover src={coverUrl} />
      </StyledCoverWrapper>
      <StyledAudioPlayerWrapper fill>
        <StyledControlsWrapper>
          <button onClick={() => play(dispatch)}>PLAY</button>
          <button onClick={() => pause(dispatch)}>PAUSE</button>
        </StyledControlsWrapper>
        <StyledInfoWrapper>
          <StyledArtist>{artist}</StyledArtist>
          <StyledTrack>{track}</StyledTrack>
        </StyledInfoWrapper>
      </StyledAudioPlayerWrapper>
    </StyledPlayerWrapper>
  )
}
