import React, { FC } from 'react'
import styled from 'styled-components'

/**
 * Props.
 */

export interface BlockProps {
  fill?: boolean
  disableBorder?: boolean

  // We need to use className to be able to extend the styled component.
  className?: string
}

/**
 * Styles.
 */

const StyledBlock = styled.div<{ $fill?: boolean; $disableBorder?: boolean }>`
  display: flex;

  background-color: ${(p) => p.$fill && p.theme.colors.white};
  border: ${(p) => !p.$disableBorder && p.theme.borders.default};
`

/**
 * Component.
 */

export const Block: FC<BlockProps> = ({ fill, disableBorder, children, className }) => (
  <StyledBlock $fill={fill} $disableBorder={disableBorder} className={className}>
    {children}
  </StyledBlock>
)
