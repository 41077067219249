import React from 'react'
import styled from 'styled-components'

/**
 * Styles.
 */

const StyledLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`

/**
 * Component.
 */

export const Logo = () => (
  <StyledLogoWrapper>
    <img src={'/logo.png'} alt="Logo" />
  </StyledLogoWrapper>
)
