import useInterval from '@use-it/interval'
import { Discojs } from 'discojs'
import React, { createContext, Dispatch, FC, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'

import { AUDIO_DEFAULT_STATE, audioReducer, audioRef } from './audioReducer'
import { AudioActions, getStreamUrl, pause, play, setCover, setInfo } from './audioActions'

import { RadioStreamsEnum } from '../../../../commons/streams'

/**
 * Constants.
 */

const POLL_DELAY = 5000

const discojs = new Discojs({
  // @TODO: This is a test token, we'll need to replace it by a VAR ENV.
  userToken: 'jPDqzIVCqzxNNCaCQzfyFVdUYBIkMekInNXTBNhQ',
})

// @NOTE: Deleting unsafe headers to make it work.

// @ts-ignore
const { fetchHeaders } = discojs

if (fetchHeaders?.map) {
  delete fetchHeaders.map['accept-encoding']
  delete fetchHeaders.map.connection
  delete fetchHeaders.map['user-agent']
}

/**
 * Contexts.
 */

const AudioStateContext = createContext(AUDIO_DEFAULT_STATE)
const AudioDispatchContext = createContext<Dispatch<AudioActions>>(() => null)

/**
 * Hooks.
 */

export const useAudioState = () => {
  const ctx = useContext(AudioStateContext)
  return ctx
}

export const useAudioDispatch = () => {
  const ctx = useContext(AudioDispatchContext)
  return ctx
}

/**
 * Provider.
 */

export const AudioProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(audioReducer, AUDIO_DEFAULT_STATE)

  const pollCallback = useCallback(
    async (selectedStream: RadioStreamsEnum) => {
      const rawResponse = await fetch(getPollUrl(selectedStream))
      const { artist, track }: { artist?: string; track?: string } = await rawResponse.json()

      if (artist !== state.artist || track !== state.track) {
        setInfo(dispatch, { artist, track })

        // @TODO: Make cover fetcher smarter.
        const { results } = await discojs.searchDatabase({ artist, track })
        setCover(dispatch, results.length ? results[0].cover_image : undefined)
      }
    },
    [state.artist, state.track],
  )

  useEffect(() => {
    play(dispatch)

    // Request `artist` and `track` every time `pollUrl` is changing.
    pollCallback(state.selectedStream)

    return () => pause(dispatch)
  }, [state.selectedStream])

  // Poll `artist` and `track` to track changes over time.
  useInterval(() => pollCallback(state.selectedStream), POLL_DELAY)

  return (
    <AudioStateContext.Provider value={state}>
      <AudioDispatchContext.Provider value={dispatch}>
        <audio ref={audioRef} src={getStreamUrl(state.selectedStream)} preload="auto" />
        {children}
      </AudioDispatchContext.Provider>
    </AudioStateContext.Provider>
  )
}

/**
 * Helpers.
 */

const getPollUrl = (selectedStream: RadioStreamsEnum) =>
  `https://secousse.tv/player/title_json.php?mount=${selectedStream}`
